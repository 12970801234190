import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 1100px;

  @media only screen and (max-width: 1101px) {
    width: 100%;
  }
`;

export const ResortFazenda = styled.div`
  background-color: #fff;
  padding-top: 67px;
  padding-bottom: 100px;
  display: flex;
  width: 100%;

  @media only screen and (max-width: 1025px) {
    padding-top: 47px;
    padding-bottom: 80px;
  }

  @media only screen and (max-width: 650px) {
    padding-top: 27px;
    padding-bottom: 40px;
  }
`;

export const Conteudo = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 50px;

  @media only screen and (max-width: 1200px) {
    padding: 0px 25px;
    padding-top: 50px;
  }

  @media only screen and (max-width: 650px) {
    width: 100%;
    padding-top: 50px;
    text-align: center;
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const ConteudoMobile = styled.div`
  display: none;
  width: 250px;
  height: 75px;
  margin-top: 50px;
  margin: auto;

  @media only screen and (max-width: 900px) {
    display: inline-block;

    min-height: 150px;
  }
`;

export const Bloco = styled.div`
  width: 22%;
  float: left;
  display: inline-block;
  border: 1px solid #d1bebe;
  margin-right: 33px;
  margin-bottom: 33px;
  padding: 27px;
  max-width: 250px;
  height: 109px;
  div:nth-child(4n) {
    margin-right: 0px;
  }

  @media only screen and (max-width: 1200px) {
    width: 250px;
    float: none;
  }

  @media only screen and (max-width: 650px) {
    margin-right: 0px;
    padding: 20px;
    max-width: 250px;
    height: 85px;
  }
`;

export const Imagem = styled.div`
  width: 45%;
  float: left;
  display: inline-block;

  img {
    float: left;
    position: relative;
    bottom: 8px;
  }
`;

export const Texto = styled.div`
  width: 55%;
  float: left;
  display: inline-block;

  p {
    color: #392c23;
    font-size: 15px !important;
    line-height: 1em;
    font-family: "GilroyBold", sans-serif;
  }

  @media only screen and (max-width: 650px) {
    p {
      font-size: 16px;
    }
  }
`;

export const BlocoMobile = styled.div`
  width: 80%;
  display: inline-block;
  margin: auto;
  border: 1px solid #d1bebe;
  padding: 15px;
  margin-top: 20px;
`;
