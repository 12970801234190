import styled from "styled-components";

import bgTtop from "../../../assets/pacotes/top-bar.png";
import bg from "../../../assets/pacotes/carnaval-bg.jpg";
import ornamentRight from "../../../assets/pacotes/bottom-floater.png";
import ornamentLeft from "../../../assets/pacotes/half-floater.png";
import logoFooter from "../../../assets/pacotes/logo-rodape.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  min-height: 2350px;
  background: #1d454f;

  @media only screen and (max-width: 812px) {
    min-height: 2200px;
  }
  @media only screen and (max-width: 696px) {
    min-height: 1900px;
  }
  @media only screen and (max-width: 540px) {
    min-height: 1750px;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 872px;
  height: auto;
`;
export const MineMenu = styled.div`
  display: flex;
  width: 100%;
  min-height: 20px;
  padding: 10px;
  background: #1b5d3d;
  justify-content: space-between;
  a {
    padding: 10px 20px;
    background: #fe7422;
    color: #fff;
  }
`;
export const TopBar = styled.img.attrs({ src: bgTtop })`
  width: 100%;
  height: auto;
  margin-bottom: 0;
  z-index: 2;
`;
export const GroupBg = styled.div`
  position: relative;
  display: flex;
  margin-top: -23px;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;

  h1 {
    font-family: "GilroyBold";
    font-size: 70px;
    position: absolute;
    color: #fff;
    z-index: 2;
    margin-bottom: 200px;
    letter-spacing: -1px;
    text-shadow: 3px 3px 8px rgba(51, 51, 51, 0.43);
    line-height: 1em;
    @media only screen and (max-width: 696px) {
      font-size: 50px;
    }
    @media only screen and (max-width: 540px) {
      font-size: 35px;
    }
  }
`;
export const Bg = styled.img.attrs({ src: bg })`
  width: 100%;
  height: auto;
  z-index: 1;
`;

export const MainGroup = styled.div`
  position: relative;
  width: 100%;
  min-height: 1100px;
  background: #fff;
  padding-bottom: 50px;
  margin-bottom: 100px;
`;

export const GroupInfo = styled.div`
  position: relative;
  margin: -120px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 700px;
  height: auto;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  z-index: 3;
`;

export const OrnamentOne = styled.img.attrs({ src: ornamentLeft })`
  position: absolute;
  top: -10px;
  right: -86px;
  width: 100%;
  max-width: 213px;
  height: auto;
  z-index: 4;
  @media only screen and (max-width: 696px) {
    width: 100%;
    max-width: 120px;
    height: auto;
    top: 30px;
    right: 0;
  }
  @media only screen and (max-width: 540px) {
    width: 100%;
    max-width: 100px;
    height: auto;
    top: 30px;
    right: 0;
  }
`;
export const OrnamentTwo = styled.img.attrs({ src: ornamentRight })`
  position: absolute;
  bottom: 100px;
  left: -87px;
  width: 100%;
  max-width: 115px;
  height: auto;
  z-index: 4;
  @media only screen and (max-width: 696px) {
    width: 100%;
    max-width: 80px;
    height: auto;
    bottom: 350px;
  }
  @media only screen and (max-width: 540px) {
    width: 100%;
    max-width: 50px;
    height: auto;
    bottom: 400px;
  }
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width:480px
  height: auto;
  padding: 0;
  margin: 0;
  &.divisor {
    margin-bottom: 20px;
  }
  div {
    display: flex;
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  div span {
    margin-right: 5px;
  }
  div p.title {
    font-family: "GilroyLight";
    font-size: 22px;
    margin: 20px 0 20px 0;
  }
  div p {
    font-family: "GilroyLight";
    font-size: 20px;
  }
`;

export const CallToAction = styled.a`
  display: flex;
  width: 95%;
  justify-content: center;
  max-width: 220px;
  padding: 10px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  background: #fe7422;
  margin: 20px 0;
`;

export const LogoFooter = styled.img.attrs({ src: logoFooter })`
  width: 120px;
  height: auto;
`;

export const Footer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  min-height: 200px;
  padding: 20px;

  div.l_left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    img {
      width: 120px;
      height: 50px;
    }
    @media only screen and (max-width: 540px) {
      width: 100%;
    }
  }
  div.l_right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
    p.bold {
      font-family: "GilroyBold";
      font-size: 18px;
      color: #000;
      line-height: 1em;
    }
    p.bold.last {
      margin-bottom: 20px;
    }
    p {
      font-family: "GilroyRegular";
      font-size: 18px;
      color: #000;
    }
    @media only screen and (max-width: 540px) {
      width: 100%;
      margin-top: 20px;
      align-items: center;
    }
  }
  @media only screen and (max-width: 540px) {
    flex-direction: column;
  }
`;

export const Warning = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  margin: 20px 0;
`;
