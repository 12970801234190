import React from "react";

// import Off20 from "../../assets/ate-20-off.png";
// import Off40 from "../../assets/ate-40-off.png";
// import Box04 from "../../assets/box-04.png";
// import Off50 from "../../assets/ate-50-off.png";

import Box04 from "../../assets/box-04.png";
import Box22 from "../../assets/box-22.png";
import Box25 from "../../assets/box-25.png";
import Box26 from "../../assets/box-26.png";
import Box27 from "../../assets/box-27.png";
import Box28 from "../../assets/box-28.png";
import Box29 from "../../assets/box-29.png";
import Box19 from "../../assets/box-19.png";

import {
  Box,
  BoxBtn,
  BoxSubTitle,
  BoxTitle,
  Container,
  Content,
  TextLegal,
  ButtonCadastro,
  Title,
  TitleBold,
  MoreInfo,
} from "./styles";

export default function Pacotes() {
  return (
    <Container>
      <Title>
        Pacotes com descontos <TitleBold>por tempo limitado</TitleBold>
      </Title>
      <Content>
        <Box className="wow fadeIn" data-wow-delay="400ms">
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box27} alt="Natal" />
          <BoxTitle>Férias de Janeiro</BoxTitle>
          <BoxSubTitle>
            Curta as férias com recreação 7 dias na semana, novo Parque Aquático
            com brinquedos infantis, rio lento, toboágua boomerang e diversas
            jacuzzis. Muita diversão e conforto pro seu descanso! Aproveite as
            oportunidades de promoção: Fique 4 e Pague 3 noites (domingo a
            quinta) ou 10% off (todos os dias inclusive fins de semana).
            <br />
            {/* <br />A partir de 3x de R$ 540,79 */}
          </BoxSubTitle>
          <MoreInfo href="pacotes/ferias">O que tem neste pacote</MoreInfo>
          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>

        <Box className="wow fadeIn" data-wow-delay="400ms">
          {/* <img className="selo" src={Off20} alt="Selo" /> */}
          <img src={Box22} alt="Box" />
          <BoxTitle>Pacote de Carnaval</BoxTitle>
          <BoxSubTitle>
            Prepare-se para um Carnaval inesquecível, com muita animação e
            atividades com total harmonia com a natureza! Hotel fazenda 3
            pinheiros é o destino perfeito para quem busca relaxamento, diversão
            e momentos únicos com a família e amigos.
            <br />
            mínimo de 5 diárias
            <br />
            {/* <br />A partir de 8x sem juros de R$ 1.276,85 */}
          </BoxSubTitle>
          <MoreInfo target="_blank" href="pacotes/carnaval">
            O que tem neste pacote
          </MoreInfo>
          <BoxBtn href="http://book.omnibees.com/hotel/6628" target="_blank">
            Comprar pacote
          </BoxBtn>
        </Box>
      </Content>
      <ButtonCadastro
        target="_blank"
        href="http://3pinheiros.com.br/cadastro/index.html">
        Cadastre-se e fique por dentro de nossas promoções e sorteios
      </ButtonCadastro>
      {/* <TextLegal>
        *Válido apenas para baixa temporada. Não é válido para feriados.
      </TextLegal> */}
      <Content />
    </Container>
  );
}
