import React, { useState } from "react";
import Swiper from "react-id-swiper";
import "react-id-swiper/lib/styles/css/swiper.css";
import "./app.css";

import {
  ResortFazenda,
  Container,
  Conteudo,
  Bloco,
  Imagem,
  Texto,
  ConteudoMobile,
  BlocoMobile,
} from "./styles";

import Titulo from "../TituloPadrao/index";

function CompontentResortFazenda({ ListaImagensResortFazenda }) {
  const RenderLista = ListaImagensResortFazenda.map(todo => (
    <Bloco>
      <Imagem>
        <img src={todo.imagem} alt={todo.imagem} />
      </Imagem>
      <Texto>
        <p>{todo.nome}</p>
      </Texto>
    </Bloco>
  ));

  const RenderListaMobile = ListaImagensResortFazenda.map(todo => (
    <BlocoMobile>
      <Imagem>
        <img src={todo.imagem} />
      </Imagem>
      <Texto>
        <p>{todo.nome}</p>
      </Texto>
    </BlocoMobile>
  ));

  const [swiper, updateSwiper] = useState(null);

  const params = {
    Swiper,
    initialSlide: 0,
    pagination: {
      el: ".swiper-pagination",
    },
    spaceBetween: 30,
    getSwiper: updateSwiper,
  };

  return (
    <ResortFazenda className="resort">
      <Container>
        <Titulo
          titulo="Resort"
          titulo2="Fazenda"
          descricao="Nosso hotel mistura a experiência relaxante do campo com todo o lazer de um resort. Confira o que você vai encontrar aqui:"
          colorTitutlo="#392C23"
          backgroundColorTitulo="#fff"
          fontSizeTitulo="62px"
          fontSizeTitulo2="52px"
          colorDescricao="#585858"
          fontSizeDescricao="22px"
        />
        <Conteudo className="wow fadeInDown" data-wow-delay="550ms">
          {RenderLista}
        </Conteudo>

        <ConteudoMobile className="wow fadeInDown" data-wow-delay="550ms">
          <Swiper {...params}>{RenderListaMobile}</Swiper>
        </ConteudoMobile>
      </Container>
    </ResortFazenda>
  );
}

export default CompontentResortFazenda;
