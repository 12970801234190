import React, { useState } from "react";
import { Conteudo, Modal, Box, CloseButton, ImagesDesktop } from "./styles";

function CompontentImagensEstrurtura({ ListaImagens }) {
  const [point, setPoint] = useState(0);
  const [open, setOpen] = useState(false);

  function AbrirImagem(id) {
    setPoint(id);
    setOpen(true);
  }

  const RenderListaImagens = ListaImagens.map(todo => (
    <div key={todo.id}>
      <img onClick={() => AbrirImagem(todo.id)} src={todo.imagem} />
    </div>
  ));

  return (
    <ImagesDesktop>
      <Conteudo className="wow fadeIn" data-wow-delay="350ms">
        {RenderListaImagens}
      </Conteudo>
      <Modal onClick={() => setOpen(false)} show={open}>
        <Box onClick={() => setOpen(true)}>
          {ListaImagens.map(
            p =>
              p.id === point && (
                <>
                  <img src={p.imagem} alt={p.imagem} />
                </>
              )
          )}
          <CloseButton onClick={() => setOpen(false)} />
        </Box>
      </Modal>
    </ImagesDesktop>
  );
}

export default CompontentImagensEstrurtura;
