import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 117px 0 56px 0;
  background-color: #1d4550;
  margin: 0 auto;
`;

export const Title = styled.p`
  color: #fff;
  font-size: 38px;
  line-height: 36px;
  font-family: "GilroyExtraBold";
  font-weight: bold;
  margin: 15px auto 0 auto;
  max-width: 795px;

  @media screen and (max-width: 1374px) {
    text-align: center;
  }
  @media screen and (max-width: 690px) {
    width: 90%;
  }
  @media screen and (max-width: 540px) {
    margin-top: 70px;
  }
`;

export const TitleBold = styled.span`
  color: #ffe9cb;
  font-size: 38px;
  line-height: 36px;
  font-weight: bold;
  font-family: "GilroyExtraBold";
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1130px;
  display: flex;
  justify-content: flex-start;
  justify-content: center;
  column-gap: 32px;
  align-items: flex-start;
  margin: 48px auto 0 auto;
  flex-wrap: wrap;
`;

export const Box = styled.div`
  width: 345px;
  height: 800px;
  background-color: #fff;
  position: relative;
  padding: 24px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.mobile {
    display: none;
    @media screen and (max-width: 600px) {
      display: flex;
      height: 550px;
    }
  }

  a.galleries {
    color: #fe7422;
  }

  :last-child {
    margin-right: 0;
  }

  img.selo {
    position: absolute;
    top: -46px;
    left: -30px;
  }

  @media screen and (max-width: 1374px) {
    margin: 0 auto 45px auto;

    &:last-child {
      margin: 0 auto;
    }
  }
`;

export const BoxTitle = styled.p`
  color: #0fa592;
  font-size: 24px;
  line-height: 29px;
  font-family: "Gilroy";
  font-weight: bold;
  text-align: center;
`;

export const BoxSubTitle = styled.p`
  color: #262c40;
  font-size: 18px;
  line-height: 22px;
  font-family: "BaskervilleRegular";
  font-weight: normal;
  text-align: center;
  min-height: 110px;
`;

export const BoxBtn = styled.a`
  background-color: #fe7422;
  width: 261px;
  height: 50px;
  color: #fff;
  padding: 14px 22px;
  text-align: center;
  font-size: 18px;
  line-height: 21px;
  transition: 300ms ease;

  :hover {
    transform: scale(0.98);
  }
`;

export const TextLegal = styled.div`
  width: 250px;
  margin: 16px auto;
  color: #fff;
  text-align: center;
  line-height: 20px;
`;

export const MoreInfo = styled.a`
  display: flex;
  font-family: "GilroyLight";
  text-align: center;
  text-decoration: none;
  color: #fe7422;
  margin-bottom: 15px;
  transition: 0.5s;
  &:hover {
    color: #1d4550;
  }
`;

export const ButtonCadastro = styled.a`
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 300px;
  margin: 50px auto;
  align-items: center;
  justify-content: center;
  background: #fe7422;
  color: #fff;
  font-family: "GilroyBold";
  font-size: medium;
  text-align: center;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 2px;
  transition: 0.3s;
  :hover {
    transform: scale(0.98);
  }
`;
